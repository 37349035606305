import React, { useState } from 'react';


import './ResetRequest.scss';
import icnEmail from '../../../_assets/media/images/icons/icn_email.png';
import logoYupik from '../../../_assets/media/images/logos/img_logo.png';
import { swalAlertsService } from '../../../_resources/services';
import ConnectionsService from '../../../core/services/ConnectionsService';

export const ResetRequest = (props) => {

    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [onSuccess, setOnSuccess] = useState(false)

    const sendRecovery = () => {
        if(!onSuccess){
            if (!email || !email.trim().length) {
                swalAlertsService.showToast('error', 'Debes de llenar el email');
                setErrorEmail(true);
            } else {
                console.log('sending');
                setErrorEmail(false);
                let objSend = {
                    email,
                    lastUrl: 'don-juan-botanero'
                }
                ConnectionsService.postCentral('resetPassword', objSend).then((response) => {
                    if (response && response.status === 200 && response.data && response.data.message) {
                        swalAlertsService.showToast('success', 'Se envío el correo');
                        setOnSuccess(true);
                    }else if(response && response.status != 200){
                        swalAlertsService.showToast('info', response.data.error.message);
                    }
                })
            }
        }
    }

    const handleEmailChange = (e) => {
            setEmail(e.target.value);
    }

    const goToLogin = ()=>{
        setEmail('');
        setErrorEmail(false);
        setOnSuccess(false);
        props.goLogin();
    }


    return (
        <div className="recover-container container-fluid p-0">

            <div className="row m-0 d-flex align-items-center justify-content-center h-100 w-100">

                <div className="col-12 d-flex justify-content-center">

                    <div className="row m-0 recover-main-box d-flex justify-content-center">
                        <div className="col-12 px-0 text-center">
                            <img src={logoYupik} className='recover-logo-img' alt="Logo yupik color" width="201" height="112" />
                        </div>

                        <div className="col-12 px-0 text-center font-color-purple recover-title-box">
                            <div className="row m-0">
                                <div className="col-12 px-0 font-size-24px font-weight-700 pb-2 recover-title">
                                    Recuperar contraseña
                                </div>
                                <div className="col-12 px-0 font-size-18px text-white">
                                    Ingresa el correo de tu cuenta de Yupik Menú
                                </div>
                            </div>
                        </div>

                        <div className="col-12 px-0 recover-secundary-box">
                            <div className="row m-0">
                                <div className="col-12 px-0 text-center recover-input-box ">
                                    <label for="email">
                                        <img src={icnEmail} alt="email icn" className="icon-filter" />
                                    </label>
                                    <input type="email"
                                        id='email'
                                        name='email'
                                        placeholder="ejemplo@correo.com"
                                        className={`${errorEmail ? 'form-control is-invalid' : 'form-control'}`}
                                        onChange={handleEmailChange}
                                        value={email}
                                    />
                                </div>

                                {
                                    onSuccess &&
                                    <div className="col-12 recover-alert-container success blue-dark" >
                                        ¡Gracias! Hemos envíado la información a: { email }
                                    </div>
                                }


                                {
                                    errorEmail &&
                                    <div className="col-12 px-0 text-center recover-alert-box">
                                        <small>Debes de llenar el correo</small>
                                    </div>
                                }



                                <div className="col-12 px-0 recover-submit-box">
                                    <button className="btn_send " onClick={sendRecovery}>Enviar</button>
                                    <button className="btn_cancel" onClick={() => goToLogin()}>Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


