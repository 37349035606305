
import './Loader.scss';

import imgLogo from '../../../_assets/media/images/logos/img_logo.png';

const Loader = (props) => {

    return (
        <div className="Loader-container">

            <div className="Loader-image-container">
                <img src={imgLogo} alt="Logo"/>
            </div>
            <div className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
                <div className="rect6"></div>
                <div className="rect7"></div>
                <div className="rect8"></div>
                <div className="rect9"></div>
            </div>


        </div>
    )
}

export { Loader };
