
import './OutService.scss';

import imgSearch from '../../../_assets/media/images/img_search_placeholder.png';

const OutService = (props) => {

    console.log(props);

    return (
        <div className="outService-container">

            <div className="outService-image-container">
                <img src={imgSearch} alt="Placeholder" />
            </div>

            <div className="outService-disclaimer-container">
                ¡Ya falta poco para convertirte en DJ!
            </div>

            <div className="subtitle-container">
                El servicio de rockola se activará en cuanto el establecimiento haya abierto.
            </div>

            {
                props && props.nearSchedule && props.nearSchedule.length ?
                    (
                        <div className="outService-disclaimer-container">
                            Se activará a las: <br />
                            <span className='blue'>{props.nearSchedule[0].start_time} Hrs</span>
                        </div>
                    ) :
                    (null)
            }

        </div>
    )
}

export { OutService };
