import React from "react";

import { Modal } from "react-bootstrap";

import "./ModalDefault.scss";

export const ModalDefault = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="ModalDefault-main-box"
      centered
      className={`ModalDefault-main-box ${
        props.className ? props.className : ""
      } ${props.overAll ? "overAll" : ""}`}

    >
      <Modal.Header closeButton={props.closeButton}/>

      <Modal.Body>{props.children ? props.children : "Contenido"}</Modal.Body>

      {
        // <Modal.Footer className='d-none'>
        //     <div className="row m-0 d-flex justify-content-center w-100 py-2 ">
        //         {
        //             this.props.buttonShow && !this.props.isUploading ? (
        //                 <div className="row m-0 d-flex justify-content-center w-100">
        //                     <div className="col-4">
        //                         <button type="button" className="btn-standard w-100 primary-bg toggle-content" onClick={this.sendToParent}>
        //                             {this.props.buttonTitle}
        //                         </button>
        //                     </div>
        //                 </div>):(null)
        //         }
        //     </div>
        // </Modal.Footer>
      }
    </Modal>
  );
};
