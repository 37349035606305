
import './Error.scss';

import imgSearch from '../../../_assets/media/images/img_search_placeholder.png';

const Error = (props) => {

    return (
        <div className="Error-container">

            <div className="Error-image-container">
                <img src={imgSearch} alt="Placeholder"/>
            </div>

            <div className="Error-disclaimer-container">
                Hemos buscado por todas partes pero no encontramos la rockola que buscas :(
            </div>


        </div>
    )
}

export { Error };
