
export function handleResponse(response, isLogin = false) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            // if ([401, 403].indexOf(response.status) !== -1) {
            //     // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            //     // authenticationService.logout();
            //     if (!isLogin) {
            //         window.location.reload(true);
            //     }
            // }

            const error = (data.error && data.error.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
