import { useState, useEffect, useRef } from 'react';
import './SearchBox.scss';

import _conn from '../../../core/services/ConnectionsService';
import _cookies from '../../../core/services/CookiesService';

import Select from "react-select";

import { SearchResults } from './search-box-components/';

import { Close } from '../../shared/svgs/';

import { rockolaService, swalAlertsService } from '../../../_resources/services/';

import icnSearch from '../../../_assets/media/images/icons/icn_search.png';
import icnCancel from '../../../_assets/media/images/icons/icn_cancel.png';
import icnMusic from '../../../_assets/media/images/icons/icn_music.png';
import icnClap from '../../../_assets/media/images/icons/icn_clap.png';
import icnMusicSymbol from '../../../_assets/media/images/icons/icn_music_symbol.png';

import imgPlaceholderLogo from '../../../_assets/media/images/logos/img_placeholder_logo.png';
import imgPlaceholderVideo from '../../../_assets/media/images/logos/img_placeholder_video.png';
import imgNoresultsPlaceholder from '../../../_assets/media/images/img_search_placeholder.png';
import moment from 'moment';
import { algoliaService } from '../../../_resources/services/algolia.service';

import ReactPaginate from 'react-paginate';



const SearchBox = (props) => {
    const inputRef = useRef(null);
    const [searchBoxState, setSearchBoxState] = useState({
        searchTerm: '',
        multiValue: [],
        results: [],
        isAjax: false,
        searched: false
    });

    const [rockola, setRockola] = useState(null);
    const [seconds, setSeconds] = useState(0);
    const [tags, setTags] = useState([]);
    const [videoPicked, setVideoPicked] = useState(null);
    const [location, setLocation] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [activePlaylist, setActivePlaylist] = useState(null);
    const [playlistsIds, setPlaylistsIds] = useState([]);
    const [nbHits, setNbHits] = useState(0);
    const [page, setPage] = useState(0);
    const [nbPages, setNbPages] = useState(0);

    const getStateCopy = () => {
        return JSON.parse(JSON.stringify(searchBoxState));
    }

    const onInputChanged = (e) => {
        let newString = e.target.value;
        if (!newString.length) {
            if (props.isTyping) {
                props.isTyping(false);
            } else {
                props.isTyping(true);
            }
        }
        let searchBoxStateCP = getStateCopy();
        searchBoxStateCP.searchTerm = e.target.value;
        setSearchBoxState(searchBoxStateCP);
    }

    const clearSearch = () => {
        const input = document.getElementById('searchInput');
        if (input) {
            input.value = '';
            let searchBoxStateCP = getStateCopy();
            searchBoxStateCP.searchTerm = '';
            if (!searchBoxStateCP.multiValue.length) {
                searchBoxStateCP.results = [];
            }
            setSearchBoxState(searchBoxStateCP);
            setVideoPicked(null);
            setNbHits(0);
            setNbPages(0);
        }
    }

    const tagsFilter = (tags = []) => {
        let tagsFiltered = [];

        tagsFiltered = tags.map((tag) => {
            return { value: tag.id, label: tag.name }
        });

        return tagsFiltered;
    }

    const handleMultiChange = (option) => {
        let searchBoxStateCP = getStateCopy();
        searchBoxStateCP.multiValue = option || [];
        searchBoxStateCP.results = [];
        setNbHits(0);
        setNbPages(0);
        setPage(0);
        setSearchBoxState(searchBoxStateCP);
    }

    const getPlaceName = () => {
        if (rockola && rockola.device && rockola.device.name) {
            return rockola.device.name;
        }
        return null;
    }

    const onRemoveTag = (tag) => {
        let searchBoxStateCP = getStateCopy();
        const filtered = searchBoxStateCP.multiValue.filter(el => el.value === tag.value);

        if (filtered.length) {
            const index = searchBoxStateCP.multiValue.indexOf(filtered[0]);
            if (index >= 0) {
                searchBoxStateCP.multiValue.splice(index, 1);

                setSearchBoxState(searchBoxStateCP);

                if (!searchBoxStateCP.multiValue.length && !searchBoxStateCP.searchTerm) {
                    setVideoPicked(null);
                }
            }
        }

    }

    const renderPickedTags = () => {

        if (searchBoxState && searchBoxState.multiValue && searchBoxState.multiValue.length) {
            const tags = searchBoxState.multiValue.map((tag, index) => {

                return (
                    <div key={index}
                        className="col SearchResults-result-tag-item mouse-pointer noselect"
                        onClick={e => onRemoveTag(tag)}>
                        {tag.label}
                        <div>
                            <Close />
                        </div>
                    </div>
                )
            });
            return <div className="SearchResults-result-tags-container col-12 px-0">

                <div className="row m-0">
                    {tags}
                </div>
            </div>
        }
        return null;
    }

    useEffect(() => {
        if (props.tags && !tags.length) {
            setTags(props.tags);
        }

        if (props.rockola && !rockola) {
            setRockola(props.rockola);
        }

        if (rockola && rockola.device && rockola.device.playlists && rockola.device.playlists.length) {
            let playlists = rockola.device.playlists;
            let playlistIdsMap = [];
            playlists.map(playlist => {
                if (!playlistIdsMap.includes(playlist.playlist_id)) {
                    playlistIdsMap.push(playlist.playlist_id);
                }
            });
            setPlaylistsIds(playlistIdsMap);
            // let dayWeek = moment().weekday();
            // let hourNow = moment().format("YYYY/MM/DD HH:mm:ss");
            // let finder = playlists.filter(play => play.day_week === dayWeek);
            // if (finder && finder.length) {
            //     let activePlaylist = null;
            //     finder.map(playlist => {
            //         let start_time = moment(playlist.start_time, 'HH:mm').format('YYYY/MM/DD HH:mm:ss');
            //         let end_time = moment(playlist.end_time, 'HH:mm').format('YYYY/MM/DD HH:mm:ss');
            //         if (new Date(hourNow) >= new Date(start_time) && new Date(hourNow) <= new Date(end_time)) {
            //             setActivePlaylist(playlist);
            //         }
            //     })
            // }
        }
    }, [props]);

    async function loadDataFromAlgolia() {

        let parameters = {
            facets: [
                "*"
            ],
            facetFilters: [],
            hitsPerPage: 10,
            page: page
        };


        playlistsIds.map(playlist => {
            if (parameters['facetFilters'].length && !parameters['facetFilters'][0].includes(`playlists.id:${playlist}`)) {
                parameters['facetFilters'][0].push(`playlists.id:${playlist}`);
            } else {
                parameters['facetFilters'].push([`playlists.id:${playlist}`]);
            }
        });

        let query = '';
        let searchBoxStateCP = getStateCopy();
        if (searchBoxStateCP.searchTerm) {
            query += `${searchBoxStateCP.searchTerm}`;
        }

        if (searchBoxStateCP.multiValue && searchBoxStateCP.multiValue.length) {
            searchBoxStateCP.multiValue.map(el => {
                parameters['facetFilters'].push([`tags.name:${el.label}`]);
            });
        }
        let settings = {
            app_id: process.env.REACT_APP_ALGOLIA_APPID,
            search_api_key: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
        }

        let algoliaIndex = props.algoliaIndex ? props.algoliaIndex:'Videos';
        
        if (props.app_id && props.search_api_key) {
            settings.app_id = props.app_id;
            settings.search_api_key = props.search_api_key;
        }
        return algoliaService.getResults(query, parameters, settings, algoliaIndex);
    }

    const saveSearch = (results = 0, sendTags = [], term = '') => {
        if (props.qrDevice) {
            _conn.post('/diner/rockola/save-search', {results, tags: sendTags, search: term, deviceqr: props.qrDevice}).then((response) => {
                console.log(response);
            }).catch(err => console.log(err));
        }
       
    }

    useEffect(() => {

        let isSuscribed = true;
        const delayDebounceFn = setTimeout(() => {
            if (searchBoxState.searchTerm || searchBoxState.multiValue.length) {
                let loading = swalAlertsService.createLoading('Buscando videos', 'En breve verás los resultados');
                loading.fire();
                const videos = loadDataFromAlgolia();
                let searchBoxStateCP = getStateCopy();
                searchBoxStateCP.results = [];
                searchBoxStateCP.searched = true;
                searchBoxStateCP.isAjax = false;
                if (isSuscribed) {
                    setVideoPicked(null);
                    setIsSearching(true);
                }

                if (inputRef && inputRef.current) {
                    inputRef.current.blur();
                    toggleTyping(false);
                }
                videos.then((response) => {
                    loading.close();
                    if (response) {
                        searchBoxStateCP.results = [];
                        if (response.hits) {
                            searchBoxStateCP.results = response.hits;
                            let tagElements = [];
                            
                            if (searchBoxStateCP.multiValue && searchBoxStateCP.multiValue.length) {
                                tagElements = searchBoxStateCP.multiValue.map(el => el.value);
                            }

                            saveSearch(searchBoxStateCP.results.length, tagElements, searchBoxStateCP.searchTerm || '');

                            setNbHits(response.nbHits);
                            setNbPages(response.nbPages);
                        }

                        searchBoxStateCP.searched = true;
                        searchBoxStateCP.isAjax = false;
                    }

                    if (isSuscribed) {
                        setIsSearching(false);
                        setSearchBoxState(searchBoxStateCP);
                    }
                });
            }
        }, 1000)
        return () => { isSuscribed = false; clearTimeout(delayDebounceFn); }
    }, [searchBoxState.searchTerm.length, searchBoxState.multiValue.length, page])

    const onVideoPicked = (video) => {
        if (video) {
            setVideoPicked(video);
        }
    }

    const createCookie = (cname, cvalue, minutes) => {
        let d = new Date();
        d.setTime(d.getTime() + (minutes * 60 * 1000));

        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const getCookie = (cname) => {

        let name = cname + "=";
        let ca = document.cookie.split(';');
        let response = '';
        for (var i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                response = c.substring(name.length, c.length);
            }
        }

        if (response.length) {
            return response;
        }
        return response;
    }

    const addMinutes = (date, minutes) => {
        return new Date(date.getTime() + minutes * 60000);
    }

    // Simulate a location service
    const getUserLocation = (onSuccess, onError) => {
        // Bust the call stack so Safari doesn't choke.
        setTimeout(function () {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
        });
    };


    // const getLocation = () => {
    //     navigator.geolocation.getCurrentPosition(
    //         (success) => {
    //             console.log(success);
    //         },
    //         (error) => {
    //             console.log(error);
    //         },
    //         {
    //             enableHighAccuracy: true,
    //             timeout: 5000,
    //             maximumAge: 0
    //         }
    //     );
    // }
    // const handleLocationPermission = () => {
    //     getUserLocation((position) => {
    //         console.log(position);
    //     }, (err) => {
    //         swalAlertsService.showToast('error', 'ubicacion negada');
    //     });
    //     // navigator.permissions
    //     //     .query({name:'geolocation'})
    //     //     .then(function(result) {
    //     //         if (result.state == 'granted') {
    //     //             report(result.state);
    //     //             // getLocation();
    //     //
    //     //             getUserLocation((position) => {
    //     //                 console.log(position);
    //     //             }, (err) => {
    //     //                 console.log(err);
    //     //             });
    //     //         } else if (result.state == 'prompt') {
    //     //             report(result.state);
    //     //             // geoBtn.style.display = 'none';
    //     //             // getLocation();
    //     //
    //     //             getUserLocation((position) => {
    //     //                 console.log(position);
    //     //             }, (err) => {
    //     //                 console.log(err);
    //     //             });
    //     //         } else if (result.state == 'denied') {
    //     //             swalAlertsService.showToast('error', 'ubicacion negada');
    //     //         }
    //     //         result.onchange = function() {
    //     //             report(result.state);
    //     //         }
    //     //     });
    // }

    useEffect(() => {
        // let isSuscribed = true;
        // const avatarElement =  document.getElementById('SearchBox-avatar-box');
        // const saluteElement =  document.getElementById('SearchBox-salute-box');
        //
        // if (props.scrollItem && avatarElement && saluteElement) {
        //     const topHeight = avatarElement.scrollHeight + saluteElement.scrollHeight;
        //
        //     if (props.scrollItem.target.children.length && topHeight < props.scrollItem.target.scrollTop) {
        //         if (!isFixed && isSuscribed) {
        //             setIsFixed(true);
        //         }
        //     } else {
        //         if (isFixed && isSuscribed) {
        //             setIsFixed(false);
        //         }
        //     }
        // }
        //
        // return () => isSuscribed = false
    }, [props.scrollItem]);

    const report = (state) => {
        console.log('Permission ' + state);
    }

    const onSendVideoBtnTapped = () => {
        _conn.getExternal('https://api.ipify.org/?format=json').then(({data}) => {
            console.log(data);
            if(data.ip){
                let cookie = getCookie('song_request');
                let id_user_central = props && props.userData && props.userData.id ? props.userData.id:null;
                 // id_user_central == 152 validación para que el usuario yahir no tenga que esperar
                let valid = JSON.parse(process.env.REACT_APP_VALID_EMAILS);
                
                if (!cookie || (cookie && valid['emails'].includes(props.userData.email))) {
                    swalAlertsService.answer("¡Tienes buen gusto! Por favor confirma tu solicitud", "Una vez que confirmes, tu video se agregará a la lista de peticiones.", icnClap, "Confirmar").then((result) => {
                        if (result.isConfirmed && videoPicked) {
        
                            let loading = swalAlertsService.createLoading('Enviando Canción', 'En breve estará lista');
                            loading.fire();
                            rockolaService.postSong(
                                {
                                    video_id: videoPicked,
                                    lat: 19.426785,
                                    lng: -99.151038,
                                    id_user_central: id_user_central,
                                    ip_user : data.ip
                                }
                            ).then((response) => {
                                loading.close();
                                if (response) {
                                    if (response.message) {
                                        swalAlertsService.showToast('success', response.message);
                                        let date = addMinutes(new Date(), 5);
        
                                        createCookie("song_request", date.getTime(), 5);
                                        setVideoPicked(null);
                                        setIsSearching(!isSearching);
                                    } else if (typeof response == 'string') {
                                        swalAlertsService.showToast('error', response);
                                    }
                                }
                            });
                        }
                    });
                } else {
        
                    let now = new Date().getTime();
                    let t = parseInt(cookie) - now;
        
        
                    let message = 'Debes esperar al menos 5 minutos para volver a solicitar una canción';
        
                    if (t >= 0) {
                        let days = Math.floor(t / (1000 * 60 * 60 * 24));
                        let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
                        let secs = Math.floor((t % (1000 * 60)) / 1000);
        
                        message = `Debes esperar al menos ${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs} para volver a solicitar una canción`;
                    }
        
                    swalAlertsService.showToast('error', message);
                }
            }
          
        })
        // _conn.getExternal('https://geolocation-db.com/json/').then((response) => {
        //     if(response && response.data && response.data.IPv4){
        //         console.log(response);
        //         let cookie = getCookie('song_request');
        //         let id_user_central = props && props.userData && props.userData.id ? props.userData.id:null;
        //          // id_user_central == 152 validación para que el usuario yahir no tenga que esperar
        //         let valid = JSON.parse(process.env.REACT_APP_VALID_EMAILS);
                
        //         if (!cookie || (cookie && valid['emails'].includes(props.userData.email))) {
        //             swalAlertsService.answer("¡Tienes buen gusto! Por favor confirma tu solicitud", "Una vez que confirmes, tu video se agregará a la lista de peticiones.", icnClap, "Confirmar").then((result) => {
        //                 if (result.isConfirmed && videoPicked) {
        
        //                     let loading = swalAlertsService.createLoading('Enviando Canción', 'En breve estará lista');
        //                     loading.fire();
        //                     rockolaService.postSong(
        //                         {
        //                             video_id: videoPicked,
        //                             lat: 19.426785,
        //                             lng: -99.151038,
        //                             id_user_central: id_user_central,
        //                             ip_user : response.data.IPv4
        //                         }
        //                     ).then((response) => {
        //                         loading.close();
        //                         if (response) {
        //                             if (response.message) {
        //                                 swalAlertsService.showToast('success', response.message);
        //                                 let date = addMinutes(new Date(), 5);
        
        //                                 createCookie("song_request", date.getTime(), 5);
        //                                 setVideoPicked(null);
        //                                 setIsSearching(!isSearching);
        //                             } else if (typeof response == 'string') {
        //                                 swalAlertsService.showToast('error', response);
        //                             }
        //                         }
        //                     });
        //                 }
        //             });
        //         } else {
        
        //             let now = new Date().getTime();
        //             let t = parseInt(cookie) - now;
        
        
        //             let message = 'Debes esperar al menos 5 minutos para volver a solicitar una canción';
        
        //             if (t >= 0) {
        //                 let days = Math.floor(t / (1000 * 60 * 60 * 24));
        //                 let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        //                 let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        //                 let secs = Math.floor((t % (1000 * 60)) / 1000);
        
        //                 message = `Debes esperar al menos ${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs} para volver a solicitar una canción`;
        //             }
        
        //             swalAlertsService.showToast('error', message);
        //         }
        //     }
          
        // })
    }

    const onInputKeyUp = (e) => {
        if (e.keyCode === 13) {
            toggleTyping(false);
            if (inputRef && inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            toggleTyping(true);
        }
    }

    const toggleTyping = (value) => {
        if (props.isTyping) {
            props.isTyping(value);
        }
    }

    const onSelectFocus = (e) => {
        toggleTyping(true);
    }

    const onSelectBlur = (e) => {
        toggleTyping(true);
    }

    const nextPage = () => {
        setPage(page + 1);
    }

    const prevPage = () => {
        if (page != 0) {
            setPage(page - 1);
        }
    }

    const goToPage = (page) => {
        setPage(page);
    }

    const handlePageClick = (page) => {
        console.log(page);
        if (page && page.hasOwnProperty('selected')) {
            setPage(page.selected);
        }
    }

    const getPagination = () => {
        let items = [];
        console.log(nbPages);
        if (nbPages <= 10) {
            for (let index = 0; index < nbPages; index++) {
                items.push(
                    <li class="page-item"><a class="page-link" onClick={() => goToPage(index)}>{index + 1}</a></li>
                )
            }
        } else {
            let half = Math.round(nbPages / 2);
            for (let index = half - 3; index < half + 3; index++) {
                items.push(
                    <li class="page-item"><a class="page-link" onClick={() => goToPage(index)}>{index + 1}</a></li>
                )
            }
        }

        return (
            <ul class="pagination">
                <li class="page-item"><a class="page-link" onClick={prevPage}>Anterior</a></li>
                {items}
                <li class="page-item"><a class="page-link" onClick={nextPage}>Siguiente</a></li>
            </ul>
        )
    }


    return (
        <div className={`SearchBox-container row m-0 ${isFixed ? 'isFixed' : ''}`}>

            <div className={`col-12 `}>
                <div className="row m-0 SearchBox-establishment-container" id='SearchBox-avatar-box'>
                    <div className="col avatar-image-container-60">
                        <img src={rockola && rockola.Device && rockola.Device.logo ? rockola.Device.logo : imgPlaceholderLogo} alt="Logo" />
                    </div>
                    <div className="col">
                        <div className="row m-0 establishment-details-row">
                            <div className="col-12 p-0 establishment-title noselect">Rockola</div>
                            <div className="col-12 p-0 establishment-subtitle noselect">{getPlaceName()}</div>
                        </div>
                    </div>
                </div>

                <div className="row m-0 SearchBox-title-container" id='SearchBox-salute-box'>

                    <div className="col-12 p-0">
                        Hoy <span>tú pones</span> el ambiente
                    </div>
                </div>

                <div className={`row m-0 SearchBox-inputs-container`}>

                    <div className="col-12 p-0 SearchBox-input-box">
                        <label htmlFor="searchInput">
                            <img src={icnSearch} alt="Search icon" />
                        </label>
                        <input type="text"
                            id='searchInput'
                            name='searchInput'
                            placeholder='Buscar por canción o artista'
                            onChange={onInputChanged}
                            onKeyUp={onInputKeyUp}
                            value={searchBoxState.searchTerm}
                        // onFocus={e => toggleTyping(true)}
                        // onBlur={e => toggleTyping(false)}
                        // ref={inputRef}
                        />

                        {
                            searchBoxState.searchTerm.length &&
                            <button className='btn-cleared' onClick={clearSearch}>
                                <img src={icnCancel} alt="Cancel icon" />
                            </button>
                        }

                    </div>
                    <div className="col-12 p-0 SearchBox-select-container">
                        <label htmlFor="devices_id" className='select-label'>
                            <img src={icnMusic} alt="Music icon" />
                        </label>

                        <Select id="devices_id"
                            name="devices_id"
                            placeholder="Buscar por género musical"
                            value={searchBoxState.multiValue}
                            options={tagsFilter(tags)}
                            onChange={handleMultiChange}
                            isMulti={true}
                            className='SearchBox-select-container'
                            classNamePrefix='SearchBox-select-container'
                            onFocus={e => toggleTyping(true)}
                            onBlur={e => toggleTyping(false)}
                            onKeyUp={e => toggleTyping(false)}
                        />

                    </div>

                    {
                        !searchBoxState.searched &&
                        <div className="col-12 px-0 SearchBox-disclaimer-container text-center">
                            El video que elijas, se reproducirá en nuestras pantallas.
                        </div>
                    }

                    {
                        searchBoxState.searched && !searchBoxState.isAjax && (searchBoxState.searchTerm || searchBoxState.multiValue.length) &&
                        <>
                            <div className="SearchResults-result-label-container col-12 px-0">
                                Resultados ({nbHits})
                            </div>

                            {renderPickedTags()}
                        </>
                    }
                </div>
            </div>

            {
                searchBoxState.searched && !searchBoxState.isAjax && (searchBoxState.searchTerm || searchBoxState.multiValue.length) && searchBoxState.results.length &&
                <>
                    <div className="col-12">
                        <SearchResults searchBoxState={searchBoxState} onVideoPicked={onVideoPicked} isSearching={isSearching} />
                    </div>
                    {/* <nav aria-label="Page navigation example">
                        {getPagination()}  
                    </nav> */}
                </>
            }

            {
                nbPages > 1 ?
                    (
                        <div>
                            <ReactPaginate
                                pageCount={nbPages}
                                pageRangeDisplayed={1}
                                previousLabel={"Anterior"}
                                nextLabel={"Siguiente"}
                                onPageChange={handlePageClick}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                containerClassName={'pagination justify-content-center'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                activeClassName={'active'}
                            />
                        </div>

                    )
                    :
                    (null)
            }


            {
                searchBoxState.searched && !searchBoxState.isAjax && (searchBoxState.searchTerm || searchBoxState.multiValue.length) && !searchBoxState.results.length &&
                <div className="col-12 SearchBox-noresults-container">
                    <img src={imgNoresultsPlaceholder} alt="Search placeholder" />
                    <p>
                        Hemos buscado por todas partes pero no encontramos ningún resultado :(
                    </p>
                </div>
            }


            {
                videoPicked &&

                <div className="col-12 p-0 SearchBox-footer-btn noselect" onClick={onSendVideoBtnTapped}>
                    <img src={icnMusicSymbol} alt="music symbol" /> Enviar video
                </div>
            }
        </div>
    );
}

export { SearchBox };
