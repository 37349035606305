import _cookies from './CookiesService';
const axios = require('axios').default;



class Conn {

    constructor() {
        this.url = this.getApiUrl();
        this.urlCentral = process.env.REACT_APP_API_PATH_CENTRAL;
    }

    async post(path, data) {
        try {
            let response = await axios.post(`${this.url}${path}`, data, this.getHeaders());
            return response;
        } catch (e) {
            return e.response;
        }
    }

    async postCentral(path, data) {
        try {
            let response = await axios.post(`${this.urlCentral}${path}`, data, this.getHeaders());
            return response;
        } catch (e) {
            return e.response;
        }
    }
    async postCentralPrivate(path, data) {

        try {
            let response = await axios.post(`${this.urlCentral}${path}`, data, this.setHttpOptions());
            return response;
        } catch (e) {
            return e.response;
        }
    }

    async put(path, data) {
        try {
            let response = await axios.put(`${this.url}${path}`,  data, this.getHeaders());
            return response;
        } catch (e) {
            return e.response;
        }
    }

    async putCentral(path, data) {
        try {
            let response = await axios.put(`${this.urlCentral}${path}`,  data, this.setHttpOptions());
            return response;
        } catch (e) {
            return e.response;
        }
    }

    async get(data) {
        try {
            let response = await axios.get(`${this.url}${data}`, this.getHeaders());
            return response;
        } catch (e) {
            return e.response;
        }
    }

    async getCentral(data){
        try {
            let response = await axios.get(`${this.urlCentral}${data}`, this.setCentalHttpOptions());
            return response;
        } catch (e) {
            return e.response;
        }
    }

    async getPrivate(data) {
        try {
            let response = await axios.get(`${this.urlCentral}${data}`, this.setHttpOptions());
            return response;
        } catch (e) {
            console.log(e);
            return e.response;
        }
    }

    async getExternal(data) {
        try {
            let response = await axios.get(data, this.getHeaders());
            return response;
        } catch (e) {
            return e.response;
        }
    }

    async delete(data) {
        try {
            let response = await axios.delete(`${this.url}${data}`, this.getHeaders());
            return response;
        } catch (e) {
            return e.response;
        }
    }

    getHeaders () {

        let headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        return headers;
    }

    getToken(){
		let user = JSON.parse(_cookies.getC('data'));

		let resp="";

		if(user){
			resp = user.access_token;
		}
		return resp;
	}

    getCentralToken(){
        let user = localStorage.getItem('access_token');

        let resp="";

		if(user){
			resp = user;
		}
		return resp;
    }

    setHttpOptions () {
		const token = this.getToken();

        let items = {
			'Content-type': 'application/json'
		}

		if (token && token.length) {
			items['Authorization'] = `Bearer ${token}`;
		}

		const headers = {
            headers: items
        }

        return headers;
    }

    setCentalHttpOptions () {
		const token = this.getCentralToken();

        let items = {
			'Content-type': 'application/json'
		}

		if (token && token.length) {
			items['Authorization'] = `Bearer ${token}`;
		}
		const headers = {
            headers: items
        }
        return headers;
    }


    getApiUrl() {
        return process.env.REACT_APP_API_PATH;
        // return window.location.hostname === 'localhost' ? 'http://localhost:3003/v1/':'https://apimenu.yupoints.com/v1/';
        // return window.location.hostname === 'localhost' ? 'http://localhost:3003/v1/':'http://68.183.30.47:3010/v1/';
    }
}
const ConnectionsService = new Conn();

export default ConnectionsService;
