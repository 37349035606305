
export function authHeader() {
    // return authorization header with jwt token
    const qrDevice = localStorage.getItem('qrDevice');
    let headers = {
        'Content-Type': 'application/json'
    }

    if (qrDevice) {
        headers['Authorization'] = qrDevice;
    }
    return headers;
}
