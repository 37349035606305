import { useState, useEffect } from 'react';
import './Footer.scss';

import imgTextures from '../../../_assets/media/images/img_textures.png';

const Footer = (props) => {
    const [year, setYear] = useState(null);

    useEffect(() => {
        const date = new Date();

        setYear(date.getFullYear());

    }, []);
    return (
        <div className="Footer-container row m-0">
            <img src={imgTextures} alt="textures"/>
            <div className="col-12 text-center">
                ©{year} Yupik Tech
            </div>
            <div className="col-12 text-center">
                <a href="https://google.com" target='_blank' rel="noopener noreferrer">Términos y condiciones</a> | <a href="https://google.com" target='_blank' rel="noopener noreferrer">Política de privacidad</a>
            </div>
        </div>
    );
};

export { Footer };
