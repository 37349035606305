import React, { useState } from 'react';
import Swal from 'sweetalert2';

import './Login.scss';

import _conn from '../../../core/services/ConnectionsService';
import _cookies from '../../../core/services/CookiesService';

import { swalAlertsService } from '../../../_resources/services/swal-alerts.service'

import icnEmail from '../../../_assets/media/images/icons/icn_email.png';
import icnPassword from '../../../_assets/media/images/icons/icn_password.png';
import icnPasswordShow from '../../../_assets/media/images/icons/icn_password_show.png';
import icnPasswordHide from '../../../_assets/media/images/icons/icn_password_hide.png';
import logoYupik from '../../../_assets/media/images/logos/img_logo.png';


export const Login = (props) => {
    const [email, setEmail] = useState('');
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [password, setPassword] = useState('');
    const [isInvalidPassword, setIsInvalidPassword] = useState(false);
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [passwordShow, setPasswordShow] = useState(false);
    const [submitted, setSubmitted] = useState(false);


    const handleInputChange = (event) => {
        if (event.target.id === 'email') {
            setEmail(event.target.value.toLowerCase());
            if (!validateEmail(event.target.value)) {
                setIsInvalidEmail(true);
            } else {
                setIsInvalidEmail(false);
            }
            setSubmitted(false);
        } else if (event.target.id === 'password') {
            setPassword(event.target.value);
            setSubmitted(false);
        }
    }

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    }

    const passwordChecker = (password) => {
        // let regexItem = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})');
        return /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{7,})/.test(password);
    }

    const handlePasswordShow = () => {
        if (passwordInputType === 'password') {
            setPasswordInputType('text');
            setPasswordShow(true);
        } else {
            setPasswordInputType('password');
            setPasswordShow(false);
        }
    }
    const sendData = async () => {
        setSubmitted(true);
        if (!email || !password) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });

            Toast.fire({
                icon: 'error',
                title: 'Debes llenar los campos'
            })
        } else {
            if(!isInvalidEmail && !isInvalidPassword){
                let objSend = {
                    email,
                    password,
                    platform: 'videos',
                    role: 'user'
                }
                let response = await _conn.postCentral('login', objSend);
                if (response && response.status === 200) {
                    if (response.data && response.data.data && response.data.data.platforms && response.data.data.platforms.length && response.data.data.platforms[0] && response.data.data.platforms[0].platform_token) {
                        localStorage.setItem('access_token', response.data.data.platforms[0].platform_token);
                        if (response.data.data.message) {
                            swalAlertsService.showToast('success', response.data.data.message);
                        } else {
                            swalAlertsService.showToast('success', 'Bienvenido');
                        }
                    }
                   props.onLogged(response.data);
                } else {
                    if (response.data && response.data.data && response.data.data.message) {
                        swalAlertsService.showToast('error', response.data.data.message);
                    } else {
                        swalAlertsService.showToast('error', 'Usuario o contraseña invalido');
                    }
                }
            }else{
                swalAlertsService.showToast('error', 'Campos invalidos');   
            }
        }
    }
    return (
        <div className="login-container container-fluid p-0">
            <div className="row m-0 d-flex align-items-center justify-content-center h-100 w-100">

                <div className="col-12 d-flex justify-content-center">

                    <React.Fragment>
                        <div className={`row m-0 login-main-box`}>
                            <div className="col-12 px-0 text-center">
                                <img src={logoYupik} alt="Logo yupik color" className='login-logo-img' width="201" height="112" />
                            </div>

                            <div className="col-12 px-0 text-center font-color-purple login-title-box">
                                <div className="row m-0">
                                    <div className="col-12 px-0 login-title">
                                        Inicia sesión con tu cuenta de Yupik! Lealtad o Menú
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 px-0 text-center login-input-box">
                                <label htmlFor="email" className="label-padding-email">
                                    <img src={icnEmail} alt="email icn" className='img-label' />
                                </label>
                                <input type="email"
                                    id='email'
                                    name='email'
                                    placeholder="ejemplo@correo.com"
                                    value={email}
                                    onChange={(e) => { handleInputChange(e) }} />
                            </div>
                            {
                                isInvalidEmail || !email ? (
                                    <div className="col-12 px-0 text-center login-alert-box">
                                        {
                                            email && isInvalidEmail ? (
                                                <small>
                                                    Debe ingresar un correo electrónico válido
                                                </small>
                                            ) : (null)
                                        }

                                        {
                                            !email && submitted ? (
                                                <small>
                                                    Debe de ingresar un correo
                                                </small>
                                            ) : (null)
                                        }
                                    </div>
                                ) : (null)
                            }

                            <div className="col-12 px-0 pt-3 text-center login-input-box">
                                <input type="email" className='d-none' />
                                <div className="col-12 px-0 text-center login-input-box">
                                    <label htmlFor="email" className="label-padding-email">
                                        <img src={icnPassword} alt="password icn" className="img-label" />
                                    </label>
                                    <input type={passwordInputType}
                                        className='psw'
                                        id='password'
                                        name='password'
                                        placeholder="Contraseña"
                                        value={password}
                                        onChange={(e) => { handleInputChange(e) }} />
                                    <button onClick={handlePasswordShow}>
                                        <img className="input-visibility-icon font-color-purple blue-dark" src={passwordShow ? icnPasswordShow : icnPasswordHide} alt="visibility icon" />
                                    </button>
                                </div>
                            </div>

                            {
                                password && isInvalidPassword ? (
                                    <div className="col-12 px-0 text-center login-alert-box">
                                        <small>Debe tener al menos 7 caracteres, incluyendo letras y números.</small>
                                    </div>
                                ) : (null)
                            }
                            {
                                !password && submitted ? (
                                    <div className="col-12 px-0 text-center login-alert-box">
                                        <small>Debe de ingresar una contraseña</small>
                                    </div>
                                ) : (null)
                            }

                            <div className="col-12 px-0 login-submit-box">
                                <button className="blue-dark-button" onClick={() => { sendData() }}>Entrar</button>
                            </div>

                            <div className="col-12 login-forgot-password" onClick={ ()=> props.goToRecover()}>
                                <span>Olvidé mi contraseña</span>
                            </div>

                            <div className="col-12 px-0 text-center login-sub-box">
                                <span className='text-white'>
                                    ¿Aún no tienes cuenta?
                                </span>

                                <button className='register-btn' onClick={ () => props.goRegister() }>¡Regístrate rápido y fácil!</button>
                            </div>
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    )
}
