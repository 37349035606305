import algoliasearch from 'algoliasearch';
// import algoliasearch from 'algoliasearch/lite';


export const algoliaService = {
    getResults,
    getBaseResults,
    clearResults
}; 

async function getResults(searchTerm, parameters = {}, settings, index = 'Videos') {
    try {
        if (!settings || !settings.app_id || !settings.search_api_key) {
            return null;
        }
        
        const searchClient = algoliasearch(
            settings.app_id,
            settings.search_api_key
        );
        // if (!parameters.hitsPerPage) {
        //     parameters['hitsPerPage'] = 100;
        // }
        const algoliaSearcher = searchClient.initIndex(index);
        const algoliHits = await algoliaSearcher.search(searchTerm, parameters);
        return algoliHits;
        
    } catch(e) {
        return e;
    }
    // {
    //     facets: ["TipoPublicacion"],
    //     facetFilters: ["TipoPublicacion:Social"],
    //     numericFilters:[]
    // }

}

async function getBaseResults(searchTerm, parameters = {}, index = 'YupikVideos') {
    try {
        const searchClient = algoliasearch(
           
        );
        // if (!parameters.hitsPerPage) {
        //     parameters['hitsPerPage'] = 100;
        // }
        const algoliaSearcher = searchClient.initIndex(index);
        return await algoliaSearcher.search(searchTerm, parameters);
    } catch(e) {
        return e;
    }
    // {
    //     facets: ["TipoPublicacion"],
    //     facetFilters: ["TipoPublicacion:Social"],
    //     numericFilters:[]
    // }

}

async function clearResults(searchTerm, index = 'YupikVideos') {
    try {
        const searchClient = algoliasearch(
            process.env.REACT_APP_ALGOLIA_APPID,
            process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
        );
        let parameters = {/*hitsPerPage: 100*/};
        const algoliaSearcher = searchClient.initIndex(index);
        const {hits} = await algoliaSearcher.search(searchTerm, parameters);
        return hits;
    } catch(e) {
        return e;
    }
    // {
    //     facets: ["TipoPublicacion"],
    //     facetFilters: ["TipoPublicacion:Social"],
    //     numericFilters:[]
    // }

}
