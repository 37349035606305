import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const apiPath = process.env.REACT_APP_API_PATH;
export const authenticationService = {
    getEstablishment,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function getEstablishment(param) {
    localStorage.removeItem('currentUser');
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiPath}/diner/rockola/establishment`, requestOptions)
        .then(response => handleResponse(response, false))
        .then(user => {

            if (user && user.rockola) {
                localStorage.setItem('currentUser', JSON.stringify(user.rockola));
                currentUserSubject.next(user.rockola);
            }

            return user;
        }, (error) => {
            localStorage.removeItem('currentUser');
            return error;
        });

}

// function register(parameters) {
//
//     const requestOptions = {
//         method: 'POST',
//         headers: authHeader(),
//         body: JSON.stringify(parameters)
//     };
//
//     return fetch(`${apiPath}/user/register`, requestOptions)
//         .then(response => handleResponse(response, false))
//         .then(preuser => {
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             if (preuser && preuser.data) {
//                 localStorage.setItem('preUser', JSON.stringify(preuser.data));
//                 preUserSubject.next(preuser);
//             }
//
//             return preuser;
//         }, (error) => {
//             return error;
//         });
// }
//
// function login(value, password, key) {
//     let body = {
//         Password: md5(password)
//     }
//     body[key] = value;
//     const requestOptions = {
//         method: 'POST',
//         headers: authHeader(),
//         body: JSON.stringify(body)
//     };
//
//     return fetch(`${apiPath}/user/login`, requestOptions)
//         .then(response => handleResponse(response, true))
//         .then(user => {
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             if (user && user.data) {
//                 localStorage.removeItem('preUser');
//                 localStorage.removeItem('tempCurrentUser');
//                 localStorage.setItem('currentUser', JSON.stringify(user.data));
//                 currentUserSubject.next(user);
//             }
//
//             return user;
//         }, (error) => {
//             return error;
//         });
// }
//
// function getUser () {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };
//     return fetch(`${apiPath}/user`, requestOptions)
//         .then(response => handleResponse(response, false))
//         .then(user => {
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             if (user && user.data) {
//                 let currentItem = localStorage.getItem('currentUser');
//
//                 if (currentItem) {
//                     try {
//                         currentItem = JSON.parse(currentItem);
//                         currentItem.Usuario = user.data;
//                         localStorage.setItem('currentUser', JSON.stringify(currentItem));
//                         currentUserSubject.next(currentItem);
//                     } catch (e) {
//
//                     }
//                 }
//                 // localStorage.removeItem('preUser');
//                 // localStorage.removeItem('tempCurrentUser');
//                 // localStorage.setItem('currentUser', JSON.stringify(user.data));
//                 // currentUserSubject.next(user);
//             }
//
//             return user;
//         }, (error) => {
//             return error;
//         });
// }
//
// function logout() {
//     // remove user from local storage to log user out
//
//     const requestOptions = {
//         method: 'POST',
//         headers: authHeader(),
//         body: JSON.stringify({})
//     };
//     return fetch(`${apiPath}/user/logout`, requestOptions)
//         .then(response => handleResponse(response, true))
//         .then(user => {
//             localStorage.removeItem('preUser');
//             localStorage.removeItem('tempCurrentUser');
//             localStorage.removeItem('currentUser');
//             currentUserSubject.next(null);
//
//             return user;
//         }, (error) => {
//             return error;
//         });
//
// }
