import React, { useState } from 'react';
import './NewPassword.scss';

import _conn from '../../../core/services/ConnectionsService';

import logoYupik from '../../../_assets/media/images/logos/img_logo.png';
import icnPassword from '../../../_assets/media/images/icons/icn_password.png';
import icnPasswordShow from '../../../_assets/media/images/icons/icn_password_show.png';
import icnPasswordHide from '../../../_assets/media/images/icons/icn_password_hide.png';
import { swalAlertsService } from '../../../_resources/services/swal-alerts.service';

export const NewPassword = (props) => {


    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [passwordInputType, setpasswordInputType] = useState('password');
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidPasswordRepeat, setInvalidPasswordRepeat] = useState(false);
    const [passwordRepeatInputType, setPasswordRepeatInputType] = useState('password');
    const [onSuccess, setOnSuccess] = useState(false);


    console.log(props);

    const handlePassword = (e) => {
        setPassword(e.target.value);
        if (passwordChecker(e.target.value) || !e.target.value.length) {
            setInvalidPassword(false);
        } else {
            setInvalidPassword(true);
        }
    }

    const handlePasswordRepeat = (e) => {
        setPasswordRepeat(e.target.value);
        if (passwordChecker(e.target.value)|| !e.target.value.length) {
            setInvalidPasswordRepeat(false);
        } else {
            setInvalidPasswordRepeat(true);
        }
    }

    const passwordChecker = (password) => {
        return /^(?=.*[a-z])(?=.*[0-9])(?=.{7,})/.test(password);
    }

    const handlePasswordShow = () =>{
        if(passwordInputType === 'password'){
            setpasswordInputType('text');
        }else{
            setpasswordInputType('password');
        }
    }

    const handlePasswordRepeatShow = () =>{
        if(passwordRepeatInputType === 'password'){
            setPasswordRepeatInputType('text');
        }else{
            setPasswordRepeatInputType('password');
        }
    }

    const sendData = ()=>{

        if(!password || !password.length || !passwordRepeat || !passwordRepeat.length){
            swalAlertsService.showToast('error', 'Completa los datos');
        }else{
            if(password != passwordRepeat){
                swalAlertsService.showToast('error', 'Las contraseñas no coinciden');
            }else{
                let objSend = {
                    token: props.token,
                    newPassword: password
                }
                _conn.postCentral('changePassword', objSend).then((response)=>{
                    if(response && response.status === 200){
                        swalAlertsService.showToast('success', 'Se cambio correctamente la contraseña');
                        props.hideRecovery();
                    }else{
                        swalAlertsService('error', 'Se produjo un error al cambiar la contraseña')
                    }
                })
            }
        }

    }

    return (
        <div className="change-container container-fluid p-0">


            <div className="row m-0 d-flex align-items-center justify-content-center h-100 w-100">

                <div className="col-12 d-flex justify-content-center">

                    <div className="row m-0 change-main-box {{animationString1}}">
                        <div className="col-12 px-0 text-center">
                            <img src={logoYupik} className='change-logo-img' alt="Logo yupik color" width="201" height="112" />
                        </div>

                        <div className="col-12 px-0 text-center font-color-purple change-title-box">
                            <div className="row m-0">
                                <div className="col-12 px-0 font-size-24px font-weight-700 pb-2 label-change">
                                    Crear contraseña
                                </div>
                            </div>
                        </div>

                        {
                            (invalidPassword || invalidPasswordRepeat) &&
                            <div className="col-12 change-alert-container error {{step == 1 ? 'w-margin':''}}" >
                                La contraseña debe tener al menos 7 caracteres, incluyendo letras y números.
                            </div>
                        }

                        {
                            onSuccess &&
                            <div className="col-12 change-alert-container success {{step == 1 ? 'w-margin':''}}">
                                Tu contraseña se ha actualizado
                            </div >
                        }

                        <div className="col-12 px-0 text-center change-input-box top mb-2"  >
                            <label>
                                <img src={icnPassword} alt="password icn" className="icon-filter" />
                            </label>
                            <input type={passwordInputType}
                                id='password'
                                name='password'
                                placeholder="Nueva contraseña"
                                className='form-control'
                                onChange={handlePassword}
                                value={password}
                            />
                            <button className='mr-2'>
                               <img width={35}  height={30}  src={`${passwordInputType === 'password' ? icnPasswordHide : icnPasswordShow}`} onClick={handlePasswordShow} />
                            </button >
                        </div >
                        <div className="col-12 px-0 text-center change-input-box bottom">
                            <label>
                                <img src={icnPassword} alt="password icn" className="icon-filter" />
                            </label>
                            <input type={passwordRepeatInputType}
                                id='password2'
                                name='password2'
                                placeholder="Repetir la contraseña"
                                className='form-control'
                                onChange={handlePasswordRepeat}
                                value={passwordRepeat}
                            />
                            <button  >
                            <img width={35}  height={30}  src={`${passwordRepeatInputType === 'password' ? icnPasswordHide : icnPasswordShow}`} onClick={handlePasswordRepeatShow} />
                            </button >
                        </div >

                        {/* <div className="col-12 px-0 text-center change-alert-box" >
                        <small>{{ errorMessage }}</small>
                    </div > */}

                        <div className="col-12 px-0 change-submit-box">
                            <button className="btn_send" onClick={sendData}>Enviar</button>
                            <button className="btn_cancel" onClick={() => props.hideRecovery()}>Cancelar</button >
                        </div >
                    </div >

                </div >
            </div >
        </div >

    )
}
