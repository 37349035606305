import { useState, useEffect } from 'react';

import './SearchResults.scss';

import icnCheckbox from '../../../../../_assets/media/images/icons/icn_checkbox.png';
import icnCheckboxSelected from '../../../../../_assets/media/images/icons/icn_checkbox_selected.png';
import imgPlaceholderVideo from '../../../../../_assets/media/images/logos/img_placeholder_video.png';

const SearchResults = (props) => {

    const [videoPicked, setVideoPicked] = useState(null);

    const onVideoPicked = (video) => {
        if (video && video.id) {
            setVideoPicked(video.id);

            if (props.onVideoPicked) {
                props.onVideoPicked(video.id);
            }
        }
    }

    useEffect(() => {
        setVideoPicked(null);
    }, [props.isSearching]);

    const getResults = () => {

        if (props.searchBoxState && props.searchBoxState.results && props.searchBoxState.results.length) {
            return props.searchBoxState.results.map((result, index) => {
                const icon = videoPicked && result.id === videoPicked ? icnCheckboxSelected:icnCheckbox;
                return (
                    <div className="col-12 SearchResults-results-video-item p-0" key={index} onClick={e => onVideoPicked(result)}>

                        <div className="row m-0">
                            <div className="col-12 SearchResults-details-container">

                                <div className="row m-0">
                                    <div className="col thumbnail-container">
                                        <img src={result.thumbnail ? result.thumbnail:imgPlaceholderVideo}
                                            alt="Video thumbnail"
                                            onError={(e)=>{e.target.onerror = null; e.target.src=imgPlaceholderVideo}}
                                        />
                                    </div>
                                    <div className="col pe-0 d-flex align-items-center">
                                        <div className="row m-0 line-height-20px">
                                            <div className="col-12 px-0 video-name">{result.title}</div>
                                            <div className="col-12 px-0 video-author">{result.autor}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 SearchResults-action-container">
                                Quiero este video

                                <button className='btn-cleared'>
                                    <img src={icon} alt="Checkbox icon" width='28' height='28'/>
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })
        }
        return null;
    }

    return (
        <div className="SearchResults-container row m-0">

            <div className="SearchResults-results-videos-container col-12 px-0">

                <div className="row m-0">

                    {getResults()}
                </div>
            </div>

        </div>
    );
}

export { SearchResults }
