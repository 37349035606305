import React, { useState } from 'react'
import Swal from 'sweetalert2';
import icnUser from '../../../_assets/media/images/icons/icn_user.png';
import icnEmail from '../../../_assets/media/images/icons/icn_email.png';
import icnPhone from '../../../_assets/media/images/icons/icn_phone.png';
import icnPassword from '../../../_assets/media/images/icons/icn_password.png';
import icnPasswordShow from '../../../_assets/media/images/icons/icn_password_show.png';
import icnPasswordHide from '../../../_assets/media/images/icons/icn_password_hide.png';
import logoYupik from '../../../_assets/media/images/logos/img_logo.png';

import { swalAlertsService } from '../../../_resources/services/swal-alerts.service'


import _conn from '../../../core/services/ConnectionsService';
import _cookies from '../../../core/services/CookiesService';

import './Register.scss';

export const Register = (props) => {

    const [step, setStep] = useState(0);
    const [passwordShow, setPasswordShow] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [isValidPhone, setisValidPhone] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [duplicatedEmail, setDuplicatedEmail] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [submitted1, setSubmitted1] = useState(false);
    const [policy, setPolicy] = useState(false);


    const handleInputChange = (event) => {

        if (event.target.id === 'ypmE') {
            setEmail(event.target.value.toLowerCase());
            if (!validateEmail(event.target.value)) {
                setIsInvalidEmail(true);
            } else {
                setIsInvalidEmail(false);
            }
            setDuplicatedEmail(duplicatedEmail);
            setSubmitted1(false);
        } else if (event.target.id === 'ypmN') {
            setName(event.target.value);
            setSubmitted1(false);
        } else if (event.target.id === 'ypmNu') {
            let isNumber = /^[0-9]*$/.test(event.target.value);
            if (isNumber) {
                let isValidPhone = telephoneChecker(event.target.value);
                if (isValidPhone) {
                    setisValidPhone(true);
                } else {
                    setisValidPhone(false);
                }
                if(event.target.value.length <= 10){
                    setPhone(event.target.value);
                }
            }
        }
        else {
            let isValidPassword = passwordChecker(event.target.value);
            if (isValidPassword) {
                setIsValidPassword(true);
            } else {
                setIsValidPassword(false)
            }
            setPassword(event.target.value);
        }
    }

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    }

    const passwordChecker = (password) => {
        // let regexItem = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})');
        return /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{7,})/.test(password);
    }

    const telephoneChecker = (telephone) => {
        // let regexTel = new RegExp('[\(]?[\+]?(\d{2}|\d{3})[\)]?[\s]?((\d{6}|\d{8})|(\d{3}[\*\.\-\s]){3}|(\d{2}[\*\.\-\s]){4}|(\d{4}[\*\.\-\s]){2})|\d{8}|\d{10}|\d{12}');
        let regexTel = new RegExp(/^[\(]?[\+]?(\d{2}|\d{3})[\)]?[\s]?((\d{6}|\d{8})|(\d{3}[\*\.\-\s]){3}|(\d{2}[\*\.\-\s]){4}|(\d{4}[\*\.\-\s]){2})|\d{8}|\d{10}|\d{12}$/);
        return regexTel.test(telephone);
    }

    const handlePasswordShow = () => {
        if (passwordInputType === 'password') {
            setPasswordInputType('text');
            setPasswordShow(true);
        } else {
            setPasswordInputType('password');
            setPasswordShow(false);
        }
    }

    const changePolicyStatus = (event) => {
        setPolicy(event.target.checked);
    }

    const checkEmail = async () => {
        const response = await _conn.postCentral('checkuser', { email });
        console.log(response.status);
        if (response.status === 200) {
            setDuplicatedEmail(true);
            return true
        } else {
            setDuplicatedEmail(false);
            return false
        }

    }

    const sendData = async () => {
        setSubmitted1(true);

        setDuplicatedEmail(false);
        if (!email || !password || !name || !phone || !policy) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });

            Toast.fire({
                icon: 'error',
                title: 'Debes llenar los campos'
            })
        } else {
            if (!isInvalidEmail && isValidPassword) {
                let isDuplicatedEmail = await checkEmail();

                if (isDuplicatedEmail) {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    });

                    Toast.fire({
                        icon: 'error',
                        title: 'El correo ya esta registrado'
                    })
                } else {
                    let objSend = {
                        name,
                        email,
                        password,
                        reg_type: 'email',
                        platform: 'videos'
                    }
                    let response = await _conn.postCentral('register', objSend);
                    if (response && response.status === 200 && response.data) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `Usuario registrador correctamente`
                        })
                        if(response && response.data && response.data.data && response.data.data.access_token){
                            localStorage.setItem('access_token', response.data.data.access_token);
                            props.onRegister();
                        }
                    }
                }
            } else {
                swalAlertsService.showToast('error', 'Campos invalidos');
            }

        }
    }

    return (
        <div className={`register-container ${step === 0 ? '' : 'bg-step-2'} container-fluid p-0`}>
            <div className="row m-0 d-flex align-items-center justify-content-center h-100 w-100">

                <div className="col-12 d-flex justify-content-center py-5" >

                    <div className="row m-0 register-main-box {{animationString1}} justify-content-center">

                        <div className="col-12 px-0 text-center">
                            <img src={logoYupik} alt="Logo yupoints color" width="201" height="112" className='register-logo' />
                        </div>

                        <div className="col-12 px-0 text-center font-color-purple register-title-box">
                            <div className="row m-0">
                                <div className="col-12 px-0 register-title">
                                    ¡Regístrate rápido y fácil!
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-0 register-secundary-box">

                            <div className="row m-0">
                                <div className={`col-12 px-0 text-center register-input-box`}>
                                    <label htmlFor="ypmN" className='label-padding label-icon'>
                                        <img src={icnUser} alt="email icn" className="img-label" />
                                    </label>
                                    <input type="text"
                                        id='ypmN'
                                        name='ypmN'
                                        onChange={(e) => { handleInputChange(e, 'ypmN') }}
                                        value={name}
                                        placeholder="Ej: Alejandro" />
                                </div>
                                {
                                    submitted1 && !name ? (
                                        <div className="col-12 px-0 font-color-535353 line-height-15px register-alert-box alerted">
                                            <small>Debe de ingresar un nombre</small>
                                        </div>
                                    ) : (null)
                                }
                                <div className={`col-12 px-0 text-center register-input-box second`}>
                                    <label htmlFor="ypmNu" className='label-padding label-icon'>
                                        <img src={icnPhone} alt="email icn" className="img-label" />
                                    </label>
                                    <input type="phone"
                                    inputmode="numeric"
                                        id='ypmNu'
                                        name='ypmNu'
                                        placeholder="ej : 5555555555"
                                        onChange={(e) => { handleInputChange(e, 'ypmNu') }}
                                        value={phone} />
                                </div>

                                {
                                    !isValidPhone && phone ? (
                                        <div className="col-12 px-0 font-color-535353 line-height-15px register-alert-box">
                                            <small>Debe de ingresar un numero valido</small>
                                        </div>
                                    ) : (null)
                                }
                                {
                                    submitted1 && !phone ? (
                                        <div className="col-12 px-0 font-color-535353 line-height-15px register-alert-box">
                                            <small>Debe de ingresar un numero</small>
                                        </div>
                                    ) : (null)
                                }

                                <div className={`col-12 px-0 text-center register-input-box second`}>
                                    <label htmlFor="ypmE" className='label-padding label-icon'>
                                        <img src={icnEmail} alt="email icn" className="img-label" />
                                    </label>
                                    <input type="text"
                                        id='ypmE'
                                        name='ypmE'
                                        value={email}
                                        onChange={(e) => { handleInputChange(e, 'ypmE') }}
                                        placeholder="ejemplo@correo.com"
                                    />
                                </div>

                                {
                                    isInvalidEmail || duplicatedEmail || !email ? (
                                        <div className="col-12 px-0 font-color-535353 line-height-15px register-alert-box">
                                            {
                                                email && isInvalidEmail ? (
                                                    <small>
                                                        Debe ingresar un correo electrónico válido
                                                    </small>
                                                ) : (null)
                                            }

                                            {
                                                !email && submitted1 ? (
                                                    <small>
                                                        Debe de ingresar un correo
                                                    </small>
                                                ) : (null)
                                            }

                                            {
                                                email && duplicatedEmail && !isInvalidEmail ? (
                                                    <small>
                                                        Correo electrónico ya registrado, por favor intenta con otro
                                                    </small>
                                                ) : (null)
                                            }
                                        </div>
                                    ) : (null)
                                }

                                {/* <div className={`col-12 px-0 text-center register-input-box second ${this.blurredInput('ypmP', this.state.password)}`}> */}
                                <div className={`col-12 px-0 text-center register-input-box second `}>
                                    <label htmlFor="ypmP" className='label-icon'>
                                        <img src={icnPassword} alt="password icn" className="img-label" />
                                    </label>
                                    <input type={passwordInputType}
                                        id='ypmP'
                                        name='ypmP'
                                        placeholder="Contraseña"
                                        onKeyUp={(e) => { handleInputChange(e, 'ypmP') }} />
                                    <button onClick={handlePasswordShow}>
                                        <img className="input-visibility-icon font-color-purple blue-dark" src={passwordShow ? icnPasswordShow : icnPasswordHide} alt="visibility icon" />
                                    </button>
                                </div>
                                {
                                    !isValidPassword && password ? (
                                        <div className={`col-12 px-0 font-color-535353 line-height-15px register-alert-box`}>
                                            <small>
                                                Debe tener al menos 7 caracteres, incluyendo letras y números.
                                            </small>
                                        </div>
                                    ) : (null)
                                }

                                {
                                    !password && submitted1 ? (
                                        <div className={`col-12 px-0 font-color-535353 line-height-15px register-alert-box`}>
                                            <small>
                                                Debes de ingresar una contraseña
                                            </small>
                                        </div>
                                    ) : (null)
                                }


                                <div className="col-12 px-0 register-terms-box mt-2">
                                    <div className="row m-0">
                                        <div className="col px-0 check-box">
                                            <input id='termCheckBox'
                                                name='termCheckBox'
                                                type="checkbox"
                                                className="blue-dark-button"
                                                onChange={(e) => { changePolicyStatus(e) }} />
                                            <label htmlFor="termCheckBox"></label>
                                        </div>
                                        <div className="col pl-2 pr-0 text-left line-height-18px terms-box text-white">
                                            <small>
                                                He leído, entendido y acepto los <a href="https://menu.yupik.io/terms.html" target="_blank" rel="noopener noreferrer" className="blue-dark">Términos y Condiciones Generales</a> de Yupoints Menú, así como su <a href="https://menu.yupik.io/privacy.html" target="_blank" rel="noopener noreferrer" className="blue-dark">Política de Privacidad</a> y, por lo tanto, estoy de acuerdo en el uso y procesamiento de datos personales.
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                {
                                    !policy && submitted1 ? (
                                        <div className="col-12 px-0 font-color-535353 line-height-15px register-alert-box">
                                            <small>
                                                Debes aceptar los términos y política de privacidad
                                            </small>
                                        </div>
                                    ) : (null)
                                }
                                <div className="col-12 px-0 register-submit-box">
                                    <button className="blue-dark-button" onClick={() => { sendData() }}>Entrar</button>
                                </div>
                                <div className="col-12 px-0 register-box">
                                    <button className="register-btn-register" onClick={() => { props.goLogin() }}>Ya tengo una cuenta</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
