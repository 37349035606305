import './Header.scss';

import imgLogo from '../../../_assets/media/images/logos/img_logo.png';
import placeholderUser from '../../../_assets/media/images/icons/icn_user_login.png';
import { useEffect, useState } from 'react';

const Header = (props) => {

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if(props && props.userData && props.userData.name){
            setUserData(props.userData);
        }
    }, [props])
    

    return (
        <div className="Header-container">
            <div className="img-logo-container">
                <img src={imgLogo} alt="Logo" width='100' height='38' />
            </div>
            {
                userData &&
                <div className='user-container'>
                    <img className='placeholderUser' src={placeholderUser} alt="" />
                    <span className='labelUser'>Hola, {userData.name} </span>
                </div>
            }
        </div>
    );
};
export { Header };
