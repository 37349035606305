import React, { useState, useEffect } from "react";

import "./App.scss";

import { Header, Footer, SearchBox } from "./layout/";
import { Loader, Error, OutService } from "./shared/";
import io from "socket.io-client";

import {
  authenticationService,
  rockolaService,
  swalAlertsService,
} from "../_resources/services";
import ReactGA from "react-ga";
import { ModalDefault } from "./shared/modal/ModalDefault";
import { Register } from "./auth/register/Register";
import { Login } from "./auth/login/Login";

import _conn from '../core/services/ConnectionsService';

import icn_placeholder from "../_assets/media/images/icons/icn_placeholder-video.png";
import icn_like from "../_assets/media/images/icons/icn_like.png";
import icn_dislike from "../_assets/media/images/icons/icn_dislike.png";
import icn_selected from "../_assets/media/images/icons/icn_selected.png";
import icn_empty from "../_assets/media/images/icons/icn_empty.png";
import icn_playing_now from "../_assets/media/images/icons/icn_playing_video.png";
import moment from "moment";
import { ResetRequest } from "./auth/resetRequest/ResetRequest";
import { useLocation, useSearchParams } from "react-router-dom";
import { NewPassword } from "./auth/newPassword/NewPassword";

const App = (props) => {
  const [rockola, setRockola] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorView, setErrorView] = useState(false);
  const [outServiceView, setOutServiceView] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [qrDevice, setQrDevice] = useState(null);
  const [tags, setTags] = useState(null);
  const [scrollItem, setScrollItem] = useState(null);
  const [playingNow, setPlayingNow] = useState({
    title: '',
    autor: ''
  });
  const [showPlayingNow, setShowPlayingNow] = useState(false);
  const [record, setRecord] = useState([]);
  const websocketURL = process.env.REACT_APP_API_PATH;

  const [isDislike, setIsDislike] = useState(false);
  const [selectOption, setSelectOption] = useState([
    { op: "El audio es malo", selected: false },
    { op: "El video tiene mala calidad", selected: false },
    { op: "El volumen del video es diferente", selected: false },
    { op: "No lo quiero ver", selected: false },
  ]);

  const [nearSchedule, setNearSchedule] = useState([]);

  //modal login-register

  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showResetRequest, setShowResetRequest] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isRecovering, setIsRecovering] = useState(false);
  const [token, setToken] = useState(null);
  const [algoliaSettings, setAlgoliaSettings] = useState(null);
  const [algoliaIndex, setAlgoliaIndex] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();


  useEffect(() => {
    rockolaService.getLog().then(response => {
      if (response && response.log && response.log.length) {
        setPlayingNow(response.log[0]);
      }
    });
  }, [])

  const processGA = () => {
    ReactGA.initialize("G-D090KL911E");
    ReactGA.pageview(window.location.pathname);
  };

  useEffect(() => {

    let userLogged = localStorage.getItem('access_token');
    if (!userLogged) {
      setShowLogin(true);
    } else {
      validateUser();
    }
  }, [])


  useEffect(() => {
    let isSuscribed = true;

    let event = new URLSearchParams(location.search).get('event');
    let token = new URLSearchParams(location.search).get('token');


    if (event && event == 'recovery' && token) {
      setIsRecovering(true);
      setToken(token);
    }
    localStorage.removeItem("qrDevice");
    localStorage.removeItem("currentUser");
    if (window.location.pathname !== "/") {
      let pathname = window.location.pathname.replace(/\//g, "");
      localStorage.setItem("qrDevice", pathname);

      const socket = io(websocketURL, {
        transports: ["websocket"],
      });
      socket.on("playingNow", (data) => {
        if (data.id_device === localStorage.getItem('qrDevice')) {
          setPlayingNow(data.video);
        }
      });

      if (isSuscribed) {
        processGA();
        setQrDevice(pathname);
      }
    } else {
      if (isSuscribed) {
        setQrDevice(null);
      }
    }

    getAlgoliaSettings(isSuscribed);

    return () => (isSuscribed = false);
  }, []);

  useEffect(() => {
    let isSuscribed = true;
    setTimeout(() => {
      authenticationService.getEstablishment().then((response) => {
        if (isSuscribed) {
          console.log(response);
          if (typeof response == "string") {
            setAlgoliaIndex('Videos');
            setErrorView(true);
            setLoading(false);
          } else {

            if (response.rockola && response.rockola.device && response.rockola.device.algolia_index) {
              setAlgoliaIndex(response.rockola.device.algolia_index);
            } else {
              setAlgoliaIndex('Videos');
            }
            setRockola(response.rockola);
            let havePlaylist = false;
            let nearScheduleX = [];
            if (response && response.rockola && response.rockola.device && response.rockola.device.playlists) {
              let dayWeek = moment().isoWeekday();
              let playlists = response.rockola.device.playlists;
              let hourNow = moment().format("YYYY/MM/DD HH:mm:ss");
              let finder = playlists.filter(play => play.day_week === dayWeek);
              if (finder && finder.length) {
                finder.sort((a, b) => {
                  let aTime = moment(a.start_time, 'HH:mm').format('YYYY/MM/DD HH:mm:ss');
                  let bTime = moment(b.start_time, 'HH:mm').format('YYYY/MM/DD HH:mm:ss');
                  return new Date(aTime) - new Date(bTime);
                });
                finder.map(playlist => {
                  let start_time = moment(playlist.start_time, 'HH:mm').format('YYYY/MM/DD HH:mm:ss');
                  let end_time = moment(playlist.end_time, 'HH:mm').format('YYYY/MM/DD HH:mm:ss');
                  if (new Date(hourNow) >= new Date(start_time) && new Date(hourNow) <= new Date(end_time)) {
                    havePlaylist = true;
                  }
                  if (new Date(hourNow) < new Date(start_time)) {
                    nearScheduleX.push(playlist);
                  }
                });
              }
            }
            if (nearScheduleX.length) {
              setNearSchedule(nearScheduleX);
            }
            // debug 
            havePlaylist = true;
            // debug 

            if (havePlaylist) {
              rockolaService.getTags().then((response) => {
                rockolaService.getLog().then((response) => {
                  if (response && response.log && response.log.length) {
                    setPlayingNow(response.log[0].video);
                    let record = [];
                    if (localStorage.getItem('record')) {
                      setRecord(JSON.parse(localStorage.getItem('record')));
                      record = JSON.parse(localStorage.getItem('record')) || [];
                    }
                    if (!record.includes(response.log[0].video.id)) {
                      setShowPlayingNow(true);
                    }
                  }
                });
                if (response && response.tags) {
                  setTags(response.tags);
                  setLoading(false);
                  setErrorView(false);
                  setOutServiceView(false)
                } else {
                  setErrorView(true);
                  setLoading(false);
                }
              });
            } else {
              setLoading(false);
              setOutServiceView(true);
            }

          }
        }
      }).catch((err) => {
        setAlgoliaIndex('Videos');
      });
    }, 1000);

    return () => (isSuscribed = false);
  }, [qrDevice]);

  const validateUser = () => {
    _conn.getCentral('user')
      .then(response => {
        if (response && response.data && response.data.info) {
          setUserData(response.data.info);
          setShowRegister(false);
          setShowLogin(false);
          setActiveUser(true);
        } else {
          setShowRegister(false);
          setActiveUser(false);
          setUserData(false);
          setShowLogin(true);
        }
      }).catch((errorObj) => {
        setShowRegister(false);
        setActiveUser(false);
        setUserData(false);
        setShowLogin(true)
      })
  }

    const getAlgoliaSettings = (isSuscribed) => {
        let app_id = process.env.REACT_APP_ALGOLIA_APPID;
        let search_api_key = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
        
        _conn.get('/app/algolia').then((response) => {
            if (response && response.data && response.data.app_id && response.data.search_api_key) {
                if (isSuscribed) {
                    setAlgoliaSettings({app_id: response.data.app_id, search_api_key: response.data.search_api_key});
                }
                
            } else {
                if (isSuscribed) {
                    setAlgoliaSettings({app_id, search_api_key});
                }
                
            }
        }).catch((err) => {
            console.log(err);
            if (isSuscribed) {
                setAlgoliaSettings({app_id, search_api_key});
            }
        });
    }

  const onIsTyping = (value) => {
    setHideFooter(value);
  };

  const onClickLike = () => {
    setIsDislike(false);
    let objSend = {
      rate: "like",
      video_id: playingNow.id,
    };

    rockolaService.rate(objSend).then((response) => {
      if (response && response.code && response.code === 200) {
        let recordCP = record;
        record.push(playingNow.id);
        setRecord(recordCP);
        localStorage.setItem('record', JSON.stringify(recordCP));
        setShowPlayingNow(false);
        swalAlertsService.showToast("success", response.message);
        let options = JSON.parse(JSON.stringify(selectOption));

        options.map(op => {
          op.selected = false;
        })

        setSelectOption(options);
      }
    });
  };

  const onClickDisLike = () => {
    setIsDislike(true);
  };

  const sendDislike = () => {
    let filtered = [];
    selectOption.map((op) => {
      if (op.selected) {
        filtered.push(op.op);
      }
    });
    let objSend = {
      rate: "dislike",
      video_id: playingNow.id,
      rason: filtered && filtered.length ? JSON.stringify(filtered) : null,
    };
    rockolaService.rate(objSend).then((response) => {
      if (response && response.code && response.code === 200) {
        let recordCP = record;
        record.push(playingNow.id);
        setRecord(recordCP);
        localStorage.setItem('record', JSON.stringify(recordCP));
        setShowPlayingNow(false);
        swalAlertsService.showToast("success", response.message);
        let options = JSON.parse(JSON.stringify(selectOption));

        options.map(op => {
          op.selected = false;
        })

        setSelectOption(options);
      }
    });
    setIsDislike(false)
  };

  const handleOp = (idx) => {
    let options = JSON.parse(JSON.stringify(selectOption));
    options[idx].selected = !options[idx].selected;
    setSelectOption(options);
  };

  const getOpts = () => {
    let items = selectOption.map((op, idx) => {
      return (
        <div
          className="item-dislike"
          style={{ cursor: "pointer" }}
          onClick={() => handleOp(idx)}
          key={idx}
        >
          <img
            className="icn_select"
            src={op.selected ? icn_selected : icn_empty}
          />
          <span className="label-select">{op.op}</span>
        </div>
      );
    });

    return items;
  };

  const closePlayingNow = () => {
    setShowPlayingNow(false);
    let options = JSON.parse(JSON.stringify(selectOption));

    options.map(op => {
      op.selected = false;
    })

    setSelectOption(options);
    setIsDislike(false)
  }

  const handleToLoggin = () => {
    setShowRegister(false);
    setShowLogin(true);
  }

  const handleToRegister = () => {
    setShowLogin(false);
    setShowRegister(true);
  }

  const onLogged = () => {
    validateUser();
  }

  const clickOnPlayingNow = () => {
    setShowPlayingNow(!showPlayingNow);
  }

  const handleToRecover = () => {
    setShowLogin(false);
    setShowResetRequest(true);
  }

  const hideRecovery = () => {
    searchParams.delete('event');
    searchParams.delete('token');
    setSearchParams(searchParams);
    setIsRecovering(false);
  }

  const mainRender = () => {
    if (!loading) {
      if (!errorView) {

        if (!outServiceView) {
          return (
            <>
              <Header userData={userData} />
              <div className="row App-main">
                {
                  playingNow &&
                  <div className="col-12 playingNow-container">
                    <div className="playinNow" onClick={clickOnPlayingNow}>
                      <img src={icn_playing_now} alt="icn_playing_now" className="icn_playing_now" />
                      <span className="label_playing_now">{playingNow.title} - {playingNow.autor} </span>
                    </div>
                  </div>
                }
                <div className="col-12 p-0">
                    {
                        algoliaIndex && algoliaSettings && algoliaSettings.app_id && algoliaSettings.search_api_key ? (
                            <SearchBox
                                rockola={rockola}
                                tags={tags}
                                scrollItem={scrollItem}
                                isTyping={onIsTyping}
                                userData={userData}
                                app_id={algoliaSettings.app_id}
                                search_api_key={algoliaSettings.search_api_key}
                                algoliaIndex={algoliaIndex}
                                qrDevice={qrDevice}
                            />
                        ):(null)
                    }
                </div>
              </div>

              {!hideFooter && <Footer />}

              {
                isRecovering &&
                <ModalDefault
                  show={true}
                  className={'modal-black'}
                  backdrop="static"
                  keyboard={false}
                  animation={true}
                  closeButton={false}
                >
                  <NewPassword hideRecovery={hideRecovery} token={token} />
                </ModalDefault>
              }

              {
                showRegister && !isRecovering &&
                <ModalDefault
                  show={true}
                  className={'modal-black'}
                  backdrop="static"
                  keyboard={false}
                  animation={true}
                  closeButton={false}

                >
                  <Register goLogin={handleToLoggin} onRegister={onLogged} />
                </ModalDefault>
              }

              {
                showLogin && !isRecovering &&
                <ModalDefault
                  show={true}
                  className={'modal-black'}
                  backdrop="static"
                  keyboard={false}
                  animation={true}
                  closeButton={false}
                >
                  <Login goRegister={handleToRegister} onLogged={onLogged} goToRecover={handleToRecover} />
                </ModalDefault>
              }

              {
                showResetRequest && !isRecovering &&
                <ModalDefault
                  show={showResetRequest}
                  className={'modal-black'}
                  backdrop="static"
                  keyboard={false}
                  animation={true}
                  closeButton={false}
                >

                  <ResetRequest goLogin={handleToLoggin} />

                </ModalDefault>
              }

              {
                activeUser &&
                <ModalDefault
                  show={showPlayingNow}
                  onHide={() => closePlayingNow()}
                  showCloseBtn={true}
                >
                  <h4 className="title-modal">Reproduciendo ahora</h4>
                  <div className="video-pre" style={record && !record.includes(playingNow.id) ? {} : { marginBottom: '30px' }} >
                    <div className="row m-0 p-0">
                      <div className="col-3" style={{ alignSelf: "center" }}>
                        <img className="icn_placeholder" src={icn_placeholder} />
                      </div>
                      <div className="col-7">
                        <h2 className="name-song">{playingNow.title}</h2>
                        <h3 className="author-song">{playingNow.autor}</h3>
                      </div>
                    </div>
                  </div>

                  {
                    record && !record.includes(playingNow.id) ?
                      (
                        <div className="rate">
                          <div className="row m-0 p-0 container-rates">
                            <div
                              className="col-6 p-0 text-center"
                              style={{ cursor: "pointer" }}
                              onClick={onClickLike}
                            >
                              <img className="icn_rate" src={icn_like} />
                              <br />
                              Me gusta
                            </div>
                            <div
                              className="col-6 p-0 text-center"
                              style={{ cursor: "pointer" }}
                              onClick={onClickDisLike}
                            >
                              <img className="icn_rate" src={icn_dislike} />
                              <br />
                              No me gusta
                            </div>
                          </div>
                        </div>
                      ) :
                      (null)
                  }



                  {isDislike ? (
                    <>
                      <div className="dislike">{getOpts()}</div>

                      <div className="submit-dislike">
                        <button className="btn btn-send" onClick={sendDislike}>
                          {" "}
                          Enviar
                        </button>
                      </div>
                    </>
                  ) : null}
                </ModalDefault>
              }
            </>
          );
        } else {
          return <OutService nearSchedule={nearSchedule} />;
        }

      } else {
        return <Error />;
      }
    }
    return null;
  };
  return (
    <div
      className="App container-fluid p-0"
      onScroll={(e) => {
        setScrollItem(e);
      }}
    >
      {loading && <Loader />}
      {mainRender()}
    </div>
  );
};

export { App };
