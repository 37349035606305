import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const WSwal = withReactContent(Swal);

function showToast(type, message) {

    const Toast = WSwal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,

    })

    Toast.fire({
        icon: type,
        title: message
    })
}

function createLoading(title = 'Procesando los datos', message = 'En breve estará listo') {
    let obj = Swal.mixin({
        title: title,
        html: message,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
           Swal.showLoading()
        }
    });

    return obj;
}

function answer(title = "", descripcion, customIcon, accept, response) {
    let innerObj = {
        title: title,
        text: descripcion,
        showCancelButton: true,
        confirmButtonColor: '#00B2D2',
        cancelButtonColor: '#d33',
        confirmButtonText: accept,
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }

    // console.log(title, descripcion, accept, response, customIcon);

    if (customIcon) {
        innerObj.imageUrl = customIcon;

        innerObj.imageWidth = 59;
        innerObj.imageHeight = 46;
        innerObj.imageAlt = 'Custom icon';
    } else {

        innerObj.icon = 'warning';
    }

    let obj =  Swal.fire(innerObj)

    return obj;
}

export const swalAlertsService = {
    showToast,
    createLoading,
    answer
};
